import React from "react";
import {
  FormattedMessage,
  FormattedDate,
  Link,
  useIntl,
} from "gatsby-plugin-intl";
import dayjs from "dayjs";

import { CosmosItem } from "../types/cosmos-item.interface";
import { Prioritisation } from "../types/form/prioritisation.enum";
import { cosmosStringToArray } from "../utils/cosmosStringToArray";
import { Sports } from "../types/form/sports.enum";
import { components } from "../../types/projects";

interface Props {
  proposals: components["schemas"]["Project"][];
}

const ProposalsOverview = (props: Props) => {
  const intl = useIntl();
  const calculateDuration = (proposal: CosmosItem) => {
    const startDate = dayjs(proposal.startDate);
    const endDate = dayjs(proposal.endDate);
    const duration = endDate.diff(startDate, "week");
    return duration;
  };

  const formatTitle = (proposal: CosmosItem) => {
    if (proposal.project === Prioritisation.SYSTEM) {
      const sportArr = cosmosStringToArray(proposal.sport);

      if (sportArr.length > 1) {
        return intl.formatMessage({
          id: "multi_sport",
          defaultMessage: "Multi Sport",
        });
      } else {
        return sportArr[0];
      }
    }

    // const str = Sports[proposal.sport];
    if (!!proposal.sport) {
      return intl.formatMessage({
        id: `sport.${proposal.sport}`,
        defaultMessage: proposal.sport[0],
      });
    } else {
      return proposal.sport;
    }
  };

  return (
    <section className="proposals-overview table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>
              {" "}
              <FormattedMessage
                id="projects.project_title"
                defaultMessage="Project Title"
              />
            </th>
            <th>
              <FormattedMessage
                id="projects.timeline"
                defaultMessage="Timeline"
              />
            </th>
            <th>
              <FormattedMessage
                id="projects.duration"
                defaultMessage="Duration"
              />
            </th>
            <th>
              <FormattedMessage
                id="projects.application_progress"
                defaultMessage="Application Progress"
              />
            </th>
            <th>
              <FormattedMessage
                id="projects.actions"
                defaultMessage="Actions"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {props.proposals?.map((proposal) => {
            return (
              <tr key={proposal.id}>
                <td className="proposal-overview__name">
                  <p className="lead mb-0">{formatTitle(proposal)}</p>
                  <p className="mb-0">
                    {proposal.title && (
                      <FormattedMessage
                        id={`prioritisation.${proposal.title.toLowerCase()}`}
                        defaultMessage={proposal.title}
                      />
                    )}
                  </p>
                  <div className="badge bg-secondary">{proposal.status}</div>
                  <p className="small">
                    <FormattedMessage
                      id="last_edited"
                      defaultMessage="Last Edited:"
                    />
                    <FormattedDate
                      value={new Date(proposal.timestamp)}
                      year="numeric"
                      month="short"
                      day="2-digit"
                    />
                  </p>
                </td>
                <td className="proposal-overview__dates">
                  <FormattedDate
                    value={new Date(proposal.startDate)}
                    year="numeric"
                    month="short"
                    day="2-digit"
                  />
                  &ndash;
                  <FormattedDate
                    value={new Date(proposal.endDate)}
                    year="numeric"
                    month="short"
                    day="2-digit"
                  />
                </td>
                <td className="proposal-overview__duration">
                  <FormattedMessage
                    id="proposals.duration_in_weeks"
                    defaultMessage="{duration} weeks"
                    values={{
                      duration: calculateDuration(proposal),
                    }}
                  />
                </td>
                <td className="proposal-overview__progress">
                  <FormattedMessage
                    id="proposals.progress"
                    defaultMessage="Steps {progress}/3 complete"
                    values={{
                      progress: proposal.formStep,
                    }}
                  />
                </td>
                <td className="proposal-overview__actions">
                  <Link
                    to={`/form-wizard?id=${proposal.id}`}
                    className="btn btn-sm btn-outline-secondary"
                  >
                    <FormattedMessage
                      id="resume_proposal"
                      defaultMessage="Resume Proposal"
                    />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};
export default ProposalsOverview;
