import React, { useState, useEffect } from "react";
import { FormattedMessage, Link } from "gatsby-plugin-intl";
import { StaticImage } from "gatsby-plugin-image";
import { Spinner } from "react-bootstrap";

import { getMyProposals } from "../services/getRequest";

import Layout from "../components/layout";
import ApplicationProcessDiagram from "../components/application-process-diagram";
import ProposalsOverview from "../components/proposals-overview";
import { getAuthMe } from "../services/auth";

const HomePage = () => {
  const [allProposals, setAllProposals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [authorised, setAuthorised] = useState(false);

  useEffect(() => {
    getAuthMe().then((authJson) => {
      setLoading(false);
      if (authJson && authJson.clientPrincipal) {
        const { clientPrincipal } = authJson;

        if (clientPrincipal && clientPrincipal?.userRoles) {
          const { userRoles } = clientPrincipal;

          setAuthorised(userRoles && userRoles.includes("authorised"));

          if (userRoles && userRoles.includes("authorised")) {
            getMyProposals().then(
              (value) => {
                const { projects } = value;
                setAllProposals(projects);
                setLoading(false);
              },
              (reason) => {
                // rejection
                console.error(reason);
              }
            );
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
      }
    });
  }, []);

  return (
    <Layout>
      <h1 className="mb-3 block-title">
        <FormattedMessage
          id="app_title"
          defaultMessage="Sport Wales Performance Project Portal"
        />
      </h1>
      {loading ? (
        <div className="row mb-5">
          <div className="col">
            <h2 className="anchor-title">
              <FormattedMessage id="loading" />{" "}
              <FormattedMessage id="my_proposals_and_projects" />
            </h2>
            <Spinner animation="border" variant="secondary" role="status">
              <span className="visually-hidden">
                <FormattedMessage id="loading" />
              </span>
            </Spinner>
          </div>
        </div>
      ) : authorised ? (
        <div className="row mb-5">
          <h2 className="anchor-title">
            <FormattedMessage id="my_proposals" defaultMessage="My Proposals" />
          </h2>
          <ProposalsOverview proposals={allProposals}></ProposalsOverview>
        </div>
      ) : (
        <p></p>
      )}
      <section className="row row-cols-2 mb-5">
        {authorised ? (
          <div className="col">
            {/* new proposal */}
            <div className="card card-link rounded-0">
              <div className="card-img-top-container card-img-top-container-lg">
                <StaticImage
                  className="card-img-top"
                  src="../images/newApplication.jpg"
                  alt="sport"
                />
              </div>

              <div className="card-body bg-secondary text-white">
                <h3 className="card-title text-white">
                  <FormattedMessage
                    id="new_proposal_application_link_header"
                    defaultMessage="Let us help you to achieve your goals"
                  />
                </h3>
              </div>
              <div className="card-footer bg-primary">
                <Link to="/new-proposal" className="btn btn-primary">
                  <FormattedMessage
                    id="new_proposal_application_link_text"
                    defaultMessage="Start a new project Application"
                  />
                  <img
                    className="case-study-item__link-icon ms-1"
                    src="img/staticImages/chevron-right-white.svg"
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="col">
          {/* case studies */}
          <div className="card card-link h-100 rounded-0">
            <div className="card-img-top-container card-img-top-container-lg">
              <StaticImage
                className="card-img-top"
                src="../images/tom-action2.jpg"
                alt="sport"
              />
            </div>
            <div className="card-body bg-secondary text-white">
              <h3 className="card-title text-white">
                <FormattedMessage
                  id="case_studies_link_header"
                  defaultMessage="See what other sports are up to"
                />
              </h3>
            </div>
            <div className="card-footer bg-primary">
              <Link to="/case-studies" className="btn btn-primary">
                <FormattedMessage
                  id="case_studies"
                  defaultMessage="Case Studies"
                />
                <img
                  className="case-study-item__link-icon ms-1"
                  src="img/staticImages/chevron-right-white.svg"
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <div className="row mb-3">
        <h2 className="anchor-title">
          <FormattedMessage
            id="project_lifecycle_title"
            defaultMessage="The Lifecyle of a Performance Project"
          />
        </h2>
        <ApplicationProcessDiagram expanded={true}></ApplicationProcessDiagram>
      </div>
    </Layout>
  );
};

export default HomePage;
